
































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';    
import { IMeterReadingDetail } from "@/interfaces/meterReadingDetail"
import { IMeterReadsParams } from "@/interfaces/meterReadsParams";
import { IMeterReadSubmitParams } from "@/interfaces/meterReadSubmitParams";
import moment from 'moment';
import { IMeterReadSubmitResponse} from '../interfaces/meterReadSubmitResponse';
import ToastService from '@/services/toastService';
import ClipLoader from '@/components/elements/ClipLoader.vue';
import { GtagHelper } from '../utilities/GtagHelper';

const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");
const Metering = namespace("Metering");


@Component({
    components: {           
        ClipLoader            
    }
})    
export default class SubmitMeterRead extends Vue {

    private meterReadingSent = false;        

    public unwatch: any;
    private loading = false;     

    private currentMeterRead = '';
    private currentMeterReadDate = '';
    private readConfirmed = false;
    private showResubmitCheckbox = false;

    private GtagHelper = new GtagHelper();

    @Auth.Getter
    private getUserId!: string;

    @Auth.Getter
    private isInImpersonationMode!: boolean;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @GasAccounts.Getter
    private getSelectedDeliveryAgreementNumber!: string;

    @GasAccounts.Getter
    private getSelectedContractId!: number;

    @GasAccounts.Getter
    private getSelectedMprn!: string;

    @GasAccounts.Getter
    private isLargeUser!: boolean;      

    @Metering.Action
    private fetchPreviousMeterRead!: (params: IMeterReadsParams) => Promise<void>;

    @Metering.Action
    private submitMeterRead!: (params: IMeterReadSubmitParams) => Promise<void>;

    @Metering.Getter
    private getPreviousMeterRead!: IMeterReadingDetail;
    
    @Metering.Mutation
    private clearState!: () => void;

    @Metering.Getter
    private getMeterReadSubmitResponse!: IMeterReadSubmitResponse;


    mounted() {
        this.LoadMeterReadingData();
        //this.LoadWistiaVideoPlayer();
    }

    beforeDestroy() {
        this.unwatch();
    }

    public handleSubmitReading(): void {
        this.loading = true;

        try {

            var submitReadParams = this.meterReadSubmitParams;

            this.submitMeterRead(submitReadParams).then(() => {
                const meterReadSubmitResponse = this.getMeterReadSubmitResponse;

                if (meterReadSubmitResponse.NeedToConfirm) {
                    this.$bvToast.toast(meterReadSubmitResponse.ValidationMessage, ToastService.ErrorMessageDefaults());
                    this.showResubmitCheckbox = true;
                }
                else if (meterReadSubmitResponse.Success) {
                    this.meterReadingSent = true;

                    this.GtagHelper.Event("LumiSubmitMeterRead", this.getUserId);
                }
                else {
                    this.$bvToast.toast(meterReadSubmitResponse.ValidationMessage, ToastService.ErrorMessageDefaults());
                }

                this.loading = false;

            }, (error) => {
                this.loading = false;
                console.error(error);
            })
        } catch (error) {
            this.loading = false;
            console.error(error);
        }

        this.loading = false;

    }

    get meterReadSubmitParams(): IMeterReadSubmitParams {
        return {
            MeterRead: this.currentMeterRead,
            MeterReadDate: this.currentMeterReadDate,
            MeterReadsParams: this.meterReadParams,
            PreviousMeterRead: this.getPreviousMeterRead.Reading,
            PreviousMeterReadDate: this.getPreviousMeterRead.ReadDate,
            ReadConfirmed: this.readConfirmed
        };
    }

    get meterReadParams(): IMeterReadsParams {
        return {
            UserId: this.getUserId,
            AccountNumber: this.getSelectedAccount,
            AgreementNumber: this.getSelectedDeliveryAgreementNumber,
            ContractId: this.getSelectedContractId,                
            IsLargeUser: this.isLargeUser,
            MeterSerialNumber: this.getPreviousMeterRead?.SerialNumber,
            DmMeterSelected: false,
            Page: 1,
            Size: 10
        };
    }

    get isPendingContract() {
        var earlisestMeterReadDate = this.getPreviousMeterRead.EarliestMeterReadingDate;

        if (earlisestMeterReadDate === undefined) {
            return false;
        }

        if (moment() < moment(earlisestMeterReadDate)) {
            return true;
        }

        return false;
    }

    public LoadMeterReadingData() {
        try {
         
            this.unwatch = this.$store.watch(
                (state, getters) => getters["GasAccounts/getSelectedDeliveryAgreementNumber"],
                (newValue, oldValue) => {
                    if (newValue === null || newValue === oldValue) {

                        if (newValue === null) {                                
                            this.clearState();
                            this.meterReadingSent = false;
                        }
                        return;
                    }

                    if (this.loading === true) {
                        return; //TODO  optimisation, this watch gets called 5 times, understand why, rather than handling the problem.
                    }


                    this.clearState();
                    this.meterReadingSent = false;
                    this.loading = true;

                    this.currentMeterReadDate = moment().format("YYYY-MM-DD");
                    this.currentMeterRead = "";

                    this.fetchPreviousMeterRead(this.meterReadParams).then(() => {

                        this.loading = false;
                    }).catch(reason => {
                        this.loading = false;
                        console.error(reason);
                    });

                }
                , { immediate: true });
        } catch (e) {
            this.loading = false;
            console.error(e);
        }
    }
  
    public LoadWistiaVideoPlayer() {
        try {

            let wistiaScript_code = document.createElement('script');
            wistiaScript_code.setAttribute('src', 'https://fast.wistia.com/assets/external/E-v1.js');
            document.head.appendChild(wistiaScript_code);

            let wistiaScript_data = document.createElement('script');
            wistiaScript_data.setAttribute('src', 'https://fast.wistia.com/embed/medias/hji5gbkuzs.jsonp');
            document.head.appendChild(wistiaScript_data);

        } catch (e) {
            console.error(e);
        }
    }

    private formatReadDate(value: string): string {
        if (value == null) {

            if (this.getPreviousMeterRead.SerialNumber != null && this.getPreviousMeterRead.SerialNumber != "") {

                return "No previous billed reading available";
            }


            return "";
        }
        return moment(value).format('DD/MM/YYYY');
    }

    private formatRead(value: string): string {
        if (value == null) {

            if (this.getPreviousMeterRead.SerialNumber != null && this.getPreviousMeterRead.SerialNumber != "") {

                return "No previous billed reading available";
            }


            return "";
        }

        return value;
    }

}
