









































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import { IMeterDetail } from "@/interfaces/meterDetail";

@Component
export default class AvailableMeters extends Vue {
    @Prop({ type: String }) readonly selectedMprn!: string;
    @Prop({ type: String }) readonly meterPointAq!: string;
    @Prop({ type: String }) readonly meterSerialNumber!: string;
    @Prop({ type: Array as PropType<Array<IMeterDetail>> }) readonly availableMeters!: Array<IMeterDetail>;

    private get showAvailableMetersList(): boolean {
        return this.availableMeters.length > 1;
    }

    private onMeterSerialNumberChange(value: string) {
        this.$emit("onMeterSerialNumberChange", value);
    }
}
