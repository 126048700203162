






import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import SubmitMeterRead from "@/components/SubmitMeterRead.vue"
@Component({
    components: {
        WizardBaseStep,
        SubmitMeterRead
    }
})
    
export default class WizardStepSubmitMeterReadGas extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

}
