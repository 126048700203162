




























import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue";
import { namespace } from 'vuex-class';

const Invoices = namespace("Invoices")
const PowerAccounts = namespace("PowerAccounts");
const Auth = namespace("Auth");
const InvoicingWizard = namespace("InvoicingWizard");

@Component({
    components: {
        WizardBaseStep
    }
})
export default class WizardStepMeteringPower extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Array, default: '' }) readonly next_step_id!: string[];

    private selectedOption = 0;
    private visible = true;
    private showOption = true;
    private watchIsHalfHourly: any;

    @PowerAccounts.Getter
    private isHalfHourly!: boolean;

    @Auth.Getter
    private getUserId!: string;

    @Invoices.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    @Invoices.Action
    getCustomRangeInvoicesForAccount!: (params: { accountNum: string, userId: string, fromMonth: string, toMonth: string }) => Promise<void>;

    @InvoicingWizard.Action
    private resetJourneyNode!: () => Promise<void>;

    @InvoicingWizard.Action
    private initJourneyNode!: (option: number) => Promise<void>;

    public setVariant(value) {
        if (this.selectedOption == value)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    onSelectedOptionChanged(value) {
        this.selectedOption = value;
        this.$emit("selectedInvoicingOption", this.selectedOption);
    }

    mounted() {
        try {
            this.watchIsHalfHourly = this.$store.watch(
                (state, getters) => getters["PowerAccounts/isHalfHourly"],
                (newValue, oldValue) => {
                    if (newValue === null || newValue === oldValue) {
                        return;
                    }

                    if (newValue == true) {
                        this.showOption = false;
                    } else {
                        this.showOption = true;
                    }
             
                }
                , { immediate: true });
        } catch (e) {

            console.error(e);
        }

    }

}
