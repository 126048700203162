
































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';    
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import SubmitMeterRead from "@/components/SubmitMeterRead.vue"
import { IFileDownload } from "@/interfaces/fileDownload";
import { IMultipleMeterReadSubmitParams } from "@/interfaces/multipleMeterReadSubmitParams";
import { IMultipleMeterReadSubmitAndSendParams } from "@/interfaces/multipleMeterReadSubmitAndSendParams";
import { IMultipleMeterReadSubmitResponse } from "@/interfaces/multipleMeterReadSubmitResponse";
import { IMultipleMeterReadSubmitAndSendResponse } from "@/interfaces/multipleMeterReadSubmitAndSendResponse";
import { IMultipleMeterReadRow } from "@/interfaces/multipleMeterReadRow";
import ToastService from '@/services/toastService';
import moment from 'moment';

import { GtagHelper } from '../../../../utilities/GtagHelper';

const Metering = namespace("Metering");
const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");

@Component({
    components: {
        WizardBaseStep,
        SubmitMeterRead
    }
})        
export default class WizardStepSubmitMultipleMeterReads extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private processing = false;
    private downloadingTemplate = false;
    private meterReadsUploaded = false;
    private meterReadsSentAndSaved = false;
    private intervalHandle: (number | null) = null;
    private uploadFile: (File | null) = null;
    private uploadFileIsValid = false;
    private uploadFileName: (string | null) = null;
    private uploadFileContent: (string | null) = null;
    private isUploadActive = false;
    private estimatedMaxDownloadSeconds = 40;
    private downloadProgressCounter = 0;

    private GtagHelper = new GtagHelper();

    public uploadedMeterReadsTableColumns = [
        {
            key: 'AccountNumber',
            label: 'Account Number',
        },
        {
            key: 'AgreementNumber',
            label: 'Agreement Number',
        },
        {
            key: 'AccountName',
            label: 'Account Name',
        },
        {
            key: 'MeterPointReferenceNumber',
            label: 'Meter Point Reference Number',
        },
        {
            key: 'MeterSerialNumber',
            label: 'Meter Serial Number',
        },
        {
            key: 'PreviousReadDate',            
            label: 'Previous Reading Date',                
        },
        {
            key: 'PreviousRead',                
            label: 'Previous Reading'                
        },
        {
            key: 'CurrentReadDate',
            tdClass: 'wide-column',
            thClass: 'wide-column',
            label: 'Current Reading Date'
        },
        {
            key: 'CurrentRead',
            tdClass: 'medium-column',
            thClass: 'medium-column',
            label: 'Current Reading'
        },           
        {
            key: 'Override',
            label: 'Override?'
        },
        {
            key: 'Delete',
            label: ''
        }];     


    @Auth.Getter
    private getUserId!: string;

    @Auth.Getter
    private isInImpersonationMode!: boolean;

    @Metering.Action
    private downloadMultipleMeterReadsTemplate!: (userId: string) => Promise<void>;

    @Metering.Action
    private downloadCompletedMultipleMeterReadsTemplate!: (params: { userId: string, fileName: string }) => Promise<void>;

    @Metering.Action
    private submitMultipleMeterReads!: (params: IMultipleMeterReadSubmitParams) => Promise<void>;

    @Metering.Action
    private submitAndSendMultipleMeterReads!: (params: IMultipleMeterReadSubmitAndSendParams) => Promise<void>;

    @GasAccounts.Mutation
    private setMprnSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAgreementSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAccountSelectionDisabled!: (disabled: boolean) => void;

    @Metering.Getter
    private getMultipleMeterReadSubmitResponse!: IMultipleMeterReadSubmitResponse;

    @Metering.Getter
    private getMultipleMeterReadSubmitAndSendResponse!: IMultipleMeterReadSubmitAndSendResponse;

    @Metering.Getter
    private getCompletedMeterReadsFileName!: string;
    
    @Metering.Getter
    private multipleMeterReadsTemplateToDownload!: IFileDownload;

    @Metering.Getter
    private completedMultipleMeterReadsTemplateToDownload!: IFileDownload;

    private async onDownloadTemplate(): Promise<void> {            
        
        try {
            this.downloadingTemplate = true;
            this.showDownloadIndictator();
            await this.downloadMultipleMeterReadsTemplate(this.getUserId);
            this.fileProcess();
            
        } catch (e) {                
            console.error(e);
        }

        this.removeDownloadIndicator();
    }

    private fileProcess() {

        if (this.multipleMeterReadsTemplateToDownload == null) {                
            return null;
        }

        var fileName = this.multipleMeterReadsTemplateToDownload.fileName;
        if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob((this.multipleMeterReadsTemplateToDownload.data) as Blob, fileName as string);
        } else {
            var fileURL = window.URL.createObjectURL((this.multipleMeterReadsTemplateToDownload.data) as Blob);
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName as string);
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    }

    private fileProcessCompletedMeterReads() {

        if (this.completedMultipleMeterReadsTemplateToDownload == null) {
            return null;
        }

        var fileName = this.completedMultipleMeterReadsTemplateToDownload.fileName;
        if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob((this.completedMultipleMeterReadsTemplateToDownload.data) as Blob, fileName as string);
        } else {
            var fileURL = window.URL.createObjectURL((this.completedMultipleMeterReadsTemplateToDownload.data) as Blob);
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName as string);
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    }

    private showDownloadIndictator() {
        this.processing = true;
        this.intervalHandle = setInterval(() => {
            if (this.processing && this.downloadProgressCounter < this.estimatedMaxDownloadSeconds) {
                this.downloadProgressCounter ++;
            }
        }, 1000);
    }

    public canOverride(meterReadRow: IMultipleMeterReadRow) : boolean {

        if (meterReadRow.AccountNumberError !== null && meterReadRow.AccountNumberError !== "") {
            return false;
        }

        if (meterReadRow.AgreementNumberError !== null && meterReadRow.AgreementNumberError !== "") {
            return false;
        }

        if (this.rowHasErrors(meterReadRow)  && meterReadRow.CanOverrideCurrentRead && meterReadRow.CanOverrideCurrentReadDate) {
            return true;
        }

        return false;
    }

    public currentMeterReadDateChanged(event, meterReadRow: IMultipleMeterReadRow) {
                    
        
        if (meterReadRow.CurrentReadDate === null || meterReadRow.CurrentReadDate === "") { 
            meterReadRow.CurrentReadDateError = "Reading date is required";                
            meterReadRow.CanOverrideCurrentReadDate = false;
            return;
        }

        if (meterReadRow.CurrentReadDate === "Invalid date") {
            meterReadRow.CurrentReadDateError = "Reading date is invalid, please select a valid date";
            meterReadRow.CanOverrideCurrentReadDate = false;
            return;
        }

        var meterReadRowDate = moment(meterReadRow.CurrentReadDate);

        if (meterReadRowDate > moment()) {
            meterReadRow.CurrentReadDateError = "The date is in the future, please select an earlier date";                
            meterReadRow.CanOverrideCurrentReadDate = false;
            return;
        }

        var previousMeterReadDate = moment(meterReadRow.PreviousReadDate, "DD/MM/YYYY", true);

        if (previousMeterReadDate.isValid())
        {
            if (meterReadRowDate < previousMeterReadDate) {
                meterReadRow.CurrentReadDateError = "The date of the reading is earlier than the previous reading date we hold";
                meterReadRow.CanOverrideCurrentReadDate = true;
                return;
            }
        }

        meterReadRow.CurrentReadDateError = "";
        meterReadRow.CanOverrideCurrentReadDate = true;
        
    }

    //take from stack overflow
    private isPositiveInteger(n) {
        return n >>> 0 === parseFloat(n);
    }

    public currentMeterReadChanged(event, meterReadRow: IMultipleMeterReadRow) {


        if (meterReadRow.CurrentRead ===  null || (meterReadRow.CurrentRead as unknown) === "") {
            meterReadRow.CurrentReadError = "Reading value is required";                
            meterReadRow.CanOverrideCurrentRead = false;
            return;
        }            

        if (!this.isPositiveInteger(meterReadRow.CurrentRead)) {
            meterReadRow.CurrentReadError = "Reading is invalid, please enter a valid reading";                
            meterReadRow.CanOverrideCurrentRead = false;
            return;
        }


        if (this.isPositiveInteger(meterReadRow.PreviousRead)) {

            if (meterReadRow.PreviousRead !== null && meterReadRow.CurrentRead < parseInt(meterReadRow.PreviousRead)) {

                meterReadRow.CurrentReadError = "The reading value is lower than the previous reading value we hold";
                meterReadRow.CanOverrideCurrentRead = true;
                return;
            }

            if (meterReadRow.PreviousRead !== null && meterReadRow.CurrentRead > (parseInt(meterReadRow.PreviousRead) * 7)) {

                meterReadRow.CurrentReadError = "Reading is greater than 7 times the previous reading";
                meterReadRow.CanOverrideCurrentRead = true;
                return;
            }
        }

        meterReadRow.CurrentReadError = "";
        meterReadRow.CanOverrideCurrentRead = true;            
    }

    private removeDownloadIndicator() {
        this.processing = false;
        this.intervalHandle = null;
        this.downloadProgressCounter = 0;
    }

    private resetUploadedFile() {
        this.meterReadsUploaded = false;
        this.uploadFile = null;
        this.uploadFileIsValid = false;
        this.isUploadActive = false;
    }

    get generatedOrUploadedText(): string {
        return (this.downloadingTemplate ? "generated" : "uploaded");
    }

    get meterReadSubmitParams(): IMultipleMeterReadSubmitParams {
        return {
            userId: this.getUserId,
            uploadFileContent: "",
            uploadFileName: ""
        };
    }

    get meterReadSubmitAndSendParams(): IMultipleMeterReadSubmitAndSendParams {
        return {
            userId: this.getUserId,
            meterReads : this.getMultipleMeterReadSubmitResponseUploadedReads                
        };
    }

    get getMultipleMeterReadSubmitResponseUploadedReads(): IMultipleMeterReadRow[] {
        if (this.getMultipleMeterReadSubmitResponse != null || this.getMultipleMeterReadSubmitResponse != undefined) {
            return this.getMultipleMeterReadSubmitResponse.UploadedReads;
        }

        var result = [] as IMultipleMeterReadRow[];
        
        return result;
    }

    public submitMultipleMeterReadings(): void {

        this.downloadingTemplate = false;
        this.showDownloadIndictator();

        try {

            var submitReadParams = this.meterReadSubmitParams;

            this.submitMultipleMeterReads(submitReadParams).then(() => {
                const meterReadSubmitResponse = this.getMultipleMeterReadSubmitResponse;
                this.datePickerDateFormatFix();

                if (meterReadSubmitResponse.Success) {
                    this.meterReadsUploaded = true;
                    this.reevaulateAllMeterReads();
                }
                else {
                    this.$bvToast.toast(meterReadSubmitResponse.ValidationMessage, ToastService.ErrorMessageDefaults());
                }

                this.removeDownloadIndicator();

            }, (error) => {
                this.removeDownloadIndicator();
                console.error(error);
            })
        } catch (error) {
            this.removeDownloadIndicator();
            console.error(error);
        }            
    }

    // this is needed because the b-form-datepicker requires the v-model to be in the format YYYY-MM-DD
    public datePickerDateFormatFix(): void {
        this.getMultipleMeterReadSubmitResponseUploadedReads.forEach(meterRead => {

            if (meterRead.CurrentReadDate !== null && meterRead.CurrentReadDate !== "") {                    
                meterRead.CurrentReadDate = moment(meterRead.CurrentReadDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            }                
        });
    }

    // return the date format to DD/MM/YYYY
    public datePickerSubmitDateFormatFix(): void {
        this.getMultipleMeterReadSubmitResponseUploadedReads.forEach(meterRead => {

            if (meterRead.CurrentReadDate !== null && meterRead.CurrentReadDate !== "") {
                meterRead.CurrentReadDate = moment(meterRead.CurrentReadDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
            }
        });
    }

    get hasMeterReadUploadErrors(): boolean {            

        var hasErrors = false;

        this.getMultipleMeterReadSubmitResponseUploadedReads.forEach(meterRead => {

            if (meterRead.CurrentReadError !== null && meterRead.CurrentReadError !="" && !meterRead.Override) {
                hasErrors = true;                    
            }

            if (meterRead.CurrentReadDateError !== null && meterRead.CurrentReadDateError != "" && !meterRead.Override) {
                hasErrors = true;
            }

            if (meterRead.AccountNumberError !== null && meterRead.AccountNumberError != "") {
                hasErrors = true;
            }

            if (meterRead.AgreementNumberError !== null && meterRead.AgreementNumberError != "") {
                hasErrors = true;
            }

        });

        return hasErrors;
    }
    
    public reevaulateAllMeterReads() {

        this.getMultipleMeterReadSubmitResponseUploadedReads.forEach(meterRead => {

            this.currentMeterReadChanged(null, meterRead);
            this.currentMeterReadDateChanged(null, meterRead);

        });

    }

    public submitAndSendMultipleMeterReadings(): void {
        this.processing = true;

        try {

            this.datePickerSubmitDateFormatFix();

            var submitAndSendParams = this.meterReadSubmitAndSendParams;

            this.submitAndSendMultipleMeterReads(submitAndSendParams).then(() => {
                
                this.meterReadsSentAndSaved = true;                                           
                this.processing = false;                    
                window.scrollTo(0, 0);

                this.GtagHelper.Event("LumiSubmitBulkMeterRead", this.getUserId);
            }, (error) => {
                this.processing = false;
                this.datePickerDateFormatFix();
                console.error(error);
            })
        } catch (error) {
            this.processing = false;
            this.datePickerDateFormatFix();
            console.error(error);
        }
    }


    public async downloadSentAndSavedMeterReads(): Promise<void> {
        try {
            this.downloadingTemplate = true;
            this.showDownloadIndictator();
            await this.downloadCompletedMultipleMeterReadsTemplate({ userId: this.getUserId, fileName: this.getMultipleMeterReadSubmitAndSendResponse.FileName });
            this.fileProcessCompletedMeterReads();

        } catch (e) {
            console.error(e);
        }

        this.removeDownloadIndicator();
    }


    public loadFile(file: File) {

        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                this.uploadFileIsValid = false;
                this.isUploadActive = false;
                return;
            }
            this.uploadFileIsValid = true;
            this.isUploadActive = true;
            const reader = new FileReader();
            let model = this.meterReadSubmitParams;
            reader.onload = function () {
                const uploadContent = reader.result as string;
                model.uploadFileContent = uploadContent;
                model.uploadFileName = file.name;
            }

            reader.readAsDataURL(file);
        });
    }

    public removeSelectedUploadFile() {
        
        this.uploadFile = null;
        this.uploadFileIsValid = false;
        this.isUploadActive = false;
    }

    private uploadRowClass(row:IMultipleMeterReadRow, type) {
        if (!row || type !== 'row') return
        if (this.rowHasErrors(row ) == true) {
            return 'row-error';
        } else {
            return 'row-success';
        }
    }

    private rowHasErrors(row: IMultipleMeterReadRow) : boolean {

        if ((row.CurrentReadError !== null && row.CurrentReadError !== "") ||
            (row.CurrentReadDateError !== null && row.CurrentReadDateError != "") ||
            (row.AccountNumberError !== null && row.AccountNumberError !== "") ||
            (row.AgreementNumberError !== null && row.AgreementNumberError !== "")) {
            return true;
        }

        return false;
    }

    private formatDate(value: Date): string {

        return moment(value).format('DD/MM/YYYY');
    }
   
    private deleteMeterReadUpload(meterSerialNumber: string) {

        for (var i = 0; i <= this.getMultipleMeterReadSubmitResponseUploadedReads.length; i++) {

            if (this.getMultipleMeterReadSubmitResponseUploadedReads[i].MeterSerialNumber == meterSerialNumber) {
                this.getMultipleMeterReadSubmitResponseUploadedReads.splice(i, 1);
                i--;
            }
        }                        
    }

    mounted(): void {
        this.setAccountSelectionDisabled(true);
        this.setAgreementSelectionDisabled(true);
        this.setMprnSelectionDisabled(true);
    }

    beforeDestroy(): void {
        this.setAccountSelectionDisabled(false);
        this.setAgreementSelectionDisabled(false);
        this.setMprnSelectionDisabled(false);
    }

}
