
























































































































import moment from 'moment';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import { namespace } from 'vuex-class';

import { GtagHelper } from '../../../../utilities/GtagHelper';

const PowerMetering = namespace("PowerMetering")
const Auth = namespace("Auth")
const PowerAccounts = namespace("PowerAccounts")

@Component({
    components: {
        WizardBaseStep,
    }
})
export default class WizardStepSubmitMeterReadPower extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;

    private formSent = false;
    private formData = null;
    private watchMpanSelection: any;
    private selectedMpan = "";
    private selectedDate = "";
    private dateErrorMessage = {};
    private readErrorMessage = {};
    private selectedMeterSerialNumber: (string | null) = '';
    private maxDay = "";
    private listMeterReads: any = [];
    private newMeterReads: any = [];
    private addMeteringStepStatus = 0;
    private showErrors = false;
    private disableSendButton = false;
    private watchIsHalfHourly: any;
    private isLoaded = false;
    private newRead = {
        MeterSerialNumber: '',
        RegisterId: '',
        ReadingDate: {},
        MeterReading: {}
    };
    private currentMeterReadDate = "";
    public fields = [
        { key: 'MeterSerialNumber', label: 'Meter Serial Number', thStyle: { width: '100px' }, thClass: 'text-center', tdClass: 'text-center' },
        { key: 'RegisterId', label: 'Register ID', thStyle: { width: '25px' }, thClass: 'text-center', tdClass: 'text-center' },
        { key: 'CurrentMeterReadDate', label: 'Current Reading Date', thStyle: { width: '50px' }, thClass: 'text-center', tdClass: 'text-center' },
        { key: 'CurrentMeterRead', label: 'Current Meter Reading', thStyle: { width: '50px' }, thClass: 'text-center', tdClass: 'text-center' }
    ]

    private GtagHelper = new GtagHelper();
            

    @PowerAccounts.Getter
    private isHalfHourly!: boolean;

    @Auth.Getter
    private getUserId!: string;

    @Auth.Getter
    private isInImpersonationMode!: boolean;

    @PowerAccounts.Getter
    private getSelectedAccount!: string;

    @PowerAccounts.Getter
    private getSelectedGroupAccount!: string;

    @PowerMetering.Getter
    private getSelectedMeterSerialNumber!: string | null;

    @PowerMetering.Getter
    private getMeterReads!: Array<any>;

    @PowerMetering.Getter
    private getMeterReadStatementAdd!: Array<any>

    @PowerMetering.Action
    private fetchMeterReads!: (params: { userId: string, accountNum: string, mpan: string }) => Promise<void>;

    @PowerMetering.Action
    private fetchMeterReadStamentsAdd!: (params: { userId: string, mpan: string, accountNum: string, meterid: string }) => Promise<void>;

    @PowerMetering.Action
    private addPowerMeterRead!: (params: { userId: string, mpan: string, accountNum: string, prevMeterReadDate: string, currentMeterReadDate: string }) => Promise<boolean>;

    @PowerMetering.Action
    private sendCurrentMeterReadsToServer!: (params: { userId: string, mpan: string, accountNum: string, groupAccountNum: string, currentMeterReadDate: string, currentMeterReadArray: any }) => Promise<boolean>;

    @PowerMetering.Mutation
    private setMeterReads!: (meterReadCurrentMeterReadArray: any) => Promise<void>;

    get formattedSelectedDate() {
        const date = moment(this.selectedDate).format("DD/MM/YYYY");
        return date
    }

    mounted() {
        try {
            this.watchIsHalfHourly = this.$store.watch(
                (state, getters) => getters["PowerAccounts/isHalfHourly"],
                (newValue, oldValue) => {
                    if (newValue === null || newValue === oldValue) {
                        return;
                    }

                }
                , { immediate: true });

            this.watchMpanSelection = this.$store.watch((state, getters) => getters["PowerAccounts/getSelectedMpan"], async (newValue, oldValue) => {

                if (newValue === null || newValue === oldValue) {
                    return;
                }
                this.selectedMpan = newValue;
                await this.fetchMeterReads({ userId: this.getUserId, mpan: this.selectedMpan, accountNum: this.getSelectedAccount });
                this.disableSendButton = this.isInImpersonationMode;
                this.selectedMeterSerialNumber = this.getSelectedMeterSerialNumber;
                this.listMeterReads = this.getMeterReads;
                this.setErrorMessages();
                this.maxDay = this.setMaxDate();
                this.isLoaded = true;
            }, { immediate: true, deep: true });

        } catch (e) {
            console.error(e);
        }
    }        

    private setMaxDate() {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()+1);
        return new Date(today).toISOString().substring(0, 10);
    }

    private setErrorMessages() {
        for (let item of this.listMeterReads) {
            //For each MeterSerialNumber set blank Error Message in array
            this.readErrorMessage[item.MeterSerialNumber] = "";
            this.dateErrorMessage[item.MeterSerialNumber] = "";
        }
    }

    private async onSend(event) {
        if(!this.validateMeterSerialNumbers()){
            return
        }
        // Clear array of current meter readings
        this.newMeterReads = [];
        // Loop through the listMeterReads array
        for (let i = 0; i < this.listMeterReads.length; i++) {
            const item = this.listMeterReads[i];
            for (let j = 0; j < item.MeterReadDetail.length; j++) {
                const detail = item.MeterReadDetail[j];
                if (!this.newRead.MeterReading[item.MeterSerialNumber + detail.RegisterId] || this.newRead.MeterReading[item.MeterSerialNumber + detail.RegisterId] === null || this.newRead.MeterReading[item.MeterSerialNumber + detail.RegisterId] === '') {
                    break;
                } else {
                    if (!this.newRead.ReadingDate[item.MeterSerialNumber] || this.newRead.ReadingDate[item.MeterSerialNumber] === null || this.newRead.ReadingDate[item.MeterSerialNumber] === '') {
                        this.dateErrorMessage[item.MeterSerialNumber] = "Current meter reading date is required";
                        return;
                    }

                    this.currentMeterReadDate = moment(this.newRead.ReadingDate[item.MeterSerialNumber]).format('DD/MM/YYYY');
                    // For each detail, get the current meter reading and add it to the array
                    this.newMeterReads.push({
                        MeterSerialNumber: item.MeterSerialNumber,
                        RegisterId: detail.RegisterId,
                        CurrentMeterReadDate: moment(this.newRead.ReadingDate[item.MeterSerialNumber]).format('DD/MM/YYYY'),
                        CurrentMeterRead: this.newRead.MeterReading[item.MeterSerialNumber + detail.RegisterId]
                    });
                }
            }
        }
        const result = await this.sendCurrentMeterReadsToServer({ userId: this.getUserId, mpan: this.selectedMpan, accountNum: this.getSelectedAccount, groupAccountNum: this.getSelectedGroupAccount, currentMeterReadDate: this.currentMeterReadDate, currentMeterReadArray: this.newMeterReads });

        if (result) {
            this.formSent = true;

            this.GtagHelper.Event("LumiSubmitPowerMeterRead", this.getUserId);
        }
    }

    private isNumeric(value: any) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    private validateMeterSerialNumbers() {
        // Loop through each item in listMeterReads
        this.setErrorMessages();
        let totalReadCount = 0;
        let errors = false;
        this.showErrors = errors;
        for (let item of this.listMeterReads) {
            // For each detail in item.MeterReadDetail
            let registerCount = 0;
            let readCount = 0;
            let missingDate = 0;
            let nonNumeric = false;
            for (let detail of item.MeterReadDetail) {
                registerCount++;
                if (this.newRead.MeterReading[item.MeterSerialNumber + detail.RegisterId]) {
                    readCount++;
                    if(!this.isNumeric(this.newRead.MeterReading[item.MeterSerialNumber + detail.RegisterId]))
                    {
                        nonNumeric = true;
                    }
                    if (!this.newRead.ReadingDate[item.MeterSerialNumber] || this.newRead.ReadingDate[item.MeterSerialNumber] === null || this.newRead.ReadingDate[item.MeterSerialNumber] === '') {
                        missingDate++;
                    }
                }
            }
            if (readCount > 0 && readCount != registerCount) {
                this.readErrorMessage[item.MeterSerialNumber] = "All registers for the same Meter Serial Number must have a current meter reading"
                errors = true;
            }
            else if (nonNumeric){
                this.readErrorMessage[item.MeterSerialNumber] = "Enter a valid number"
                errors = true;
            }
            else {
                this.readErrorMessage[item.MeterSerialNumber] = "";
            }

            if (missingDate > 0) {
                this.dateErrorMessage[item.MeterSerialNumber] = "Current meter reading date is required";
                errors = true;
            }
            else {
                this.dateErrorMessage[item.MeterSerialNumber] = "";
            }

            totalReadCount += readCount;
        }

        this.showErrors = errors;

        // If none of the MeterSerialNumbers have a currentMeterRead or has errors, return false
        if (totalReadCount == 0 || errors) {
            return false;
        }

        
        return true;
    }



    beforeMount() {
        const dict = {
            messages: {
                required: (fieldName) => `Required field`,
                numeric: (fieldName) => `Enter a valid number`
            },
            names: {
                'current-meter-read': 'Current Meter Reading'
            }
        };

        this.$validator.localize('en', dict);
    }


    beforeDestroy() {
        this.watchMpanSelection();
    }
}
