



































import { Component, Watch, Vue } from "vue-property-decorator";
import BasePage from "@/components/pages/BasePage.vue";
import BaseWizard from "@/components/wizard/BaseWizard.vue";
import WizardStepMetering from "@/components/wizard/metering/gas/WizardStepMetering.vue";
import WizardStepMeteringPower from "@/components/wizard/metering/power/WizardStepMeteringPower.vue";
import WizardStepSubmitMeterRead from "@/components/wizard/metering/gas/WizardStepSubmitMeterReadGas.vue"
import WizardStepSubmitMultipleMeterReads from "@/components/wizard/metering/gas/WizardStepSubmitMultipleMeterReads.vue"
import WizardStepSubmitMeterReadPower from "@/components/wizard/metering/power/WizardStepSubmitMeterReadPower.vue"
import WizardStepViewMeterReadingHistory from "@/components/wizard/metering/gas/WizardStepViewMeterReadingHistory.vue";

import NextStepArea from "@/components/NextStepArea.vue";
import HelpAndSupport from "@/components/HelpAndSupport.vue";

import { JourneyNode } from '@/utilities/JourneyNode';
import { namespace } from 'vuex-class';


const Products = namespace("Products");
const MeteringWizard = namespace("MeteringWizard");
const GasAccounts = namespace("GasAccounts");
const PowerAccounts = namespace("PowerAccounts");

@Component({
    components: {
        BaseWizard,
        BasePage,
        WizardStepMetering,
        WizardStepMeteringPower,
        WizardStepSubmitMeterRead,
        WizardStepSubmitMultipleMeterReads,
        WizardStepSubmitMeterReadPower,
        WizardStepViewMeterReadingHistory,
        HelpAndSupport
    },
})
export default class Index extends Vue {
    private isExactActive = false;
    private watchProductSelection: any;
    private watchGasAccountSelection: any;
    private watchPowerAccountSelection: any;
    private componentKey = 0;
    private transitionName = 'fade';

    private next_step_ids = ['step-1', 'step-2', 'step-3'];

    @Watch('$route.path', { immediate: true, deep: true })
    onUrlChange(to: string, from: string) {
        if (to == null || to == undefined) {
            this.transitionName = 'fade';
            return;
        }
        const toDepth = to.split('/').length
        const fromDepth = from?.split('/').length
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    }

    @Watch('$route.query', { immediate: true })
    onQueryChange(newValue: any, oldValue: any) {
        console.log("query new Value", newValue);
    }

    @MeteringWizard.Getter
    private getRootJourney!: JourneyNode | null;

    @MeteringWizard.Action
    private resetJourneyNode!: () => Promise<void>;

    @MeteringWizard.Action
    private initJourneyNode!: (option: number) => Promise<void>;

    @MeteringWizard.Action
    private appendJourneyToJourneyNode!: (option: number) => Promise<void>;

    @Products.Getter
    private getSelectedProduct!: string;

    public async onSelectedInvoicingOption(option: number) {
        await this.initJourneyNode(option);
    }

    public async onSelectedLatestInvoicesOption(option: number) {
        await this.appendJourneyToJourneyNode(option);
    }

    public findJourney(option: number): boolean {
        let root = this.getRootJourney;
        if (root !== null) {
            while (root !== null) {
                if ((root as JourneyNode).Value == option) {
                    return true;
                }
                root = (root as JourneyNode).Next;
            }
        }
        return false;
    }

    updated() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    }

    mounted() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
        this.resetJourneyNode();
        try {

            this.watchGasAccountSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedAccount"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }
                this.resetJourneyNode();
                this.forceRerender();
            });

            this.watchPowerAccountSelection = this.$store.watch((state, getters) => getters["PowerAccounts/getSelectedGroupAccount"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }
                this.resetJourneyNode();
                this.forceRerender();
            });

            this.watchProductSelection = this.$store.watch((state, getters) => getters["Products/getSelectedProduct"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }
                this.resetJourneyNode();
                this.forceRerender();
            });

            if (this.$route.query.action == 'add') {

                //this.$router.replace({ 'query': null }); //TODO replace after fixing the linting
                //this.initJourneyNode(1); //TODO could not get the collapser to expand
            }

        } catch (e) {

            console.error(e);
        }

    }

    forceRerender() {
        this.componentKey += 1
    }

    beforeDestroy() {
        this.watchProductSelection();
        this.watchGasAccountSelection();
        this.watchPowerAccountSelection();
    }
}
