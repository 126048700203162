


























import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue";
import VueMonthlyPicker from 'vue-monthly-picker';
import ClipLoader from '@/components/elements/ClipLoader.vue';
import { namespace } from 'vuex-class';    

const Invoices = namespace("Invoices")
const GasAccounts = namespace("GasAccounts");
const Auth = namespace("Auth");
const InvoicingWizard = namespace("InvoicingWizard");

@Component({
    components: {
        WizardBaseStep,
        VueMonthlyPicker,
        ClipLoader,
    }
})
export default class WizardStepMetering extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Array, default: '' }) readonly next_step_id!: string[];

    private selectedOption = 0;
    private visible = true;        

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @GasAccounts.Getter
    private isSass!: boolean;

    @GasAccounts.Getter
    private getUserAccountsLoaded!: boolean;


    @Auth.Getter
    private isGazpromAdmin!: boolean;

    
    @Auth.Getter
    private getUserId!: string;

    @Invoices.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    @Invoices.Action
    getCustomRangeInvoicesForAccount!: (params: { accountNum: string, userId: string, fromMonth: string, toMonth: string }) => Promise<void>;

    @InvoicingWizard.Action
    private resetJourneyNode!: () => Promise<void>;

    @InvoicingWizard.Action
    private initJourneyNode!: (option: number) => Promise<void>;

    get selectedAccount() {
        return this.getSelectedAccount;
    }

    get getOffsetLg() {
        return (this.isSass || this.isGazpromAdmin) ? 3 : 1;
    }       

    public setVariant(value) {
        if (this.selectedOption == value)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    onSelectedOptionChanged(value) {
        this.selectedOption = value;
        this.$emit("selectedInvoicingOption", this.selectedOption);
    }

    //checkForSubmitAction() {

    //    if (this.$route.query.action == 'add') {

    //        if (this.id == "metering-gas")
    //        {                  
    //            //this.$refs.submitMeterRead.$el.click();
             
    //            //this.$root.$emit('bv::toggle::collapse', 'step-99');
    //        }
    //    }
    //}

    created() {            
        //this.$root.$on('openPopup', () => {

        //    //this.$refs.submitMeterRead.$el.click();
        //});

    }


    mounted() {

        //this.checkForSubmitAction();

    }
}
