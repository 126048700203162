



































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment';
import { IMeterDetail } from "@/interfaces/meterDetail";
import { IMeterReadingDetail } from "@/interfaces/meterReadingDetail";
import { IMeterReadsParams } from "@/interfaces/meterReadsParams";
import { IFileDownload } from "@/interfaces/fileDownload";
import AvailableMeters from '@/components/AvailableMeters.vue';

const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");
const Metering = namespace("Metering");

@Component({
    components: {
        AvailableMeters
    }
})
export default class MeterReadsTable extends Vue {
    public unwatch: any;
    private loading = true;
    private reportDownloading = false;
    private currentPage = 1;
    private perPage = 10;
    private selectedReadOption = "billedReads";
    private readsOptions = [
        { text: "Previously billed meter readings", value: "billedReads" },
        { text: "Daily meter readings", value: "dmReads" },
    ]        

    public meteringFields = [
        {
            key: 'ReadDate',
            label: 'Reading date',
            formatter: this.formatDate
        },
        {
            key: 'SourceDescription',
            label: 'Source'
        },
        {
            key: 'Reading',
            label: 'Meter Reading'
        },
        {
            key: 'Energy',
            label: 'Energy kWh',
            formatter: this.formatEnergy
        },
        {
            key: 'InvoiceDate',
            label: 'Billed Date',
            formatter: this.formatDate
        }];

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @GasAccounts.Getter
    private getSelectedDeliveryAgreementNumber!: string;

    @GasAccounts.Getter
    private getSelectedContractId!: number;

    @GasAccounts.Getter
    private getSelectedMprn!: string;

    @GasAccounts.Getter
    private isLargeUser!: boolean;

    @Metering.Getter
    private getSelectedMeterSerialNumber!: string;

    @Metering.Getter
    private getMeterPointAq!: string;

    @Metering.Getter
    private getShowDmReads!: boolean;

    @Metering.Getter
    private getAvailableMeters!: Array<IMeterDetail>;

    @Metering.Getter
    private getTotalMeterReadings!: number;

    @Metering.Getter
    private getMeterReads!: Array<IMeterReadingDetail>;

    @Metering.Getter
    private getDmMeterSelected!: boolean;

    @Metering.Getter
    private fileToDownload!: IFileDownload;

    @Metering.Mutation
    private setSelectedMeterSerialNumber!: (meterSerialNumber: string | null) => void;

    @Metering.Mutation
    private setDmMeterSelection!: (value: boolean) => void;

    @Metering.Mutation
    private clearState!: () => void;

    @Metering.Action
    private fetchMeterReads!: (params: IMeterReadsParams) => Promise<void>;

    @Metering.Action
    private downloadReport!: (params: IMeterReadsParams) => Promise<void>;

    private get getAvailableMetersStyling(): string {
        const defaultStyling = "order-1 order-md-2 mb-2";

        if (this.getShowDmReads) {
            return `${defaultStyling} mt-sm-0 mt-md-4`;
        }

        return defaultStyling;
    }

    private get meterReadParams(): IMeterReadsParams {
        return {
            UserId: this.getUserId,
            AccountNumber: this.getSelectedAccount,
            AgreementNumber: this.getSelectedDeliveryAgreementNumber,
            MeterSerialNumber: this.getSelectedMeterSerialNumber,
            ContractId: this.getSelectedContractId,
            DmMeterSelected: this.getDmMeterSelected,
            IsLargeUser: this.isLargeUser,
            Page: this.currentPage,
            Size: this.perPage
        };
    }

    private async onSelectedReadsChange(checked: any) {
        this.setDmMeterSelection(checked == "dmReads" ? true : false);
        this.loading = true;

        try {
            await this.fetchMeterReads(this.meterReadParams);
        } catch (e) {
            console.error(e);
        }

        this.loading = false;
    }

    private async onMeterSerialNumberChange(value: string) {
        this.setSelectedMeterSerialNumber(value);
        this.loading = true;

        try {
            await this.fetchMeterReads(this.meterReadParams);
        } catch (e) {
            console.error(e);
        }

        this.loading = false;
    }

    private async onPageChange(page: number): Promise<void> {
        this.loading = true;
        this.currentPage = page;

        try {
            await this.fetchMeterReads(this.meterReadParams);
        } catch (e) {
            console.error(e);
        }
        this.loading = false;
    }

    private async onDownloadReport(): Promise<void> {
        this.meterReadParams.Size = this.getTotalMeterReadings;

        this.reportDownloading = true;
        try {
            await this.downloadReport(this.meterReadParams);
            this.fileProcess();
        } catch (e) {
            console.error(e);
        }
        this.reportDownloading = false;
    }

    private fileProcess() {

        if (this.fileToDownload == null) {
            return null;
        }

        var fileName = this.fileToDownload.fileName;
        if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob((this.fileToDownload.data) as Blob, fileName as string);
        } else {
            var fileURL = window.URL.createObjectURL((this.fileToDownload.data) as Blob);
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName as string);
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    }

    async mounted() {
        try {
            this.unwatch = this.$store.watch(
                (state, getters) => getters["GasAccounts/getSelectedContractId"],
                (newValue, oldValue) => {
                    if (newValue === oldValue) {
                        return;
                    }

                    if (newValue === null) {
                        this.clearState();
                        return;
                    }

                    this.loading = true;

                    this.clearState();
                    this.currentPage = 1;
                    this.fetchMeterReads(this.meterReadParams).then(() => {                            
                        this.loading = false;
                    }).catch(reason => {
                        this.loading = false;
                        console.error(reason);
                    });
                },
                { immediate: true });
        } catch (e) {
            this.loading = false;
            console.error(e);
        }
    }

    beforeDestroy() {
        this.unwatch();
    }

    private formatDate(value: string): string {
        if (value === null) {
            return "";
        }
        return moment(value).format('DD/MM/YYYY');
    }

    private formatEnergy(value: number): string {
        if (value === null) {
            return "";
        }

        return value < 0 ? `${value.toString()} CR` : value.toString();
    }
}
