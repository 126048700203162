






import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import MeterReadsTable from "@/components/MeterReadsTable.vue"

@Component({
    components: {
        WizardBaseStep,
        MeterReadsTable
    }
})
export default class WizardStepViewMeterReadingHistory extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
}
